import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de';

import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
    lang: {
      locales: { de },
      current: 'de',
    },
    theme: {
        themes: {
            light: {
                primary: '#3C506E',
                secondary: '#eaeff6',
                lightgrey:'#f7f7f7'
            }
        }
    }
});